@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

iframe {
  pointer-events: none;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 1em)
    env(safe-area-inset-bottom, 0.5vh) env(safe-area-inset-left, 1.4rem);*/
  /*min-height: 100vh;*/
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#loadingProgress {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
}

/*#loadingProgress:after {*/
/*  overflow: hidden;*/
/*  display: inline-block;*/
/*  vertical-align: bottom;*/
/*  -webkit-animation: ellipsis steps(4,end) 900ms infinite;*/
/*  animation: ellipsis steps(4,end) 900ms infinite;*/
/*  content: "\2026"; !* ascii code for the ellipsis character *!*/
/*  width: 0px;*/
/*}*/

/*@keyframes ellipsis {*/
/*  to {*/
/*    width: 1.25em;*/
/*  }*/
/*}*/

/*@-webkit-keyframes ellipsis {*/
/*  to {*/
/*    width: 1.25em;*/
/*  }*/
/*}*/
